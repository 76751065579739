* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #646464;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  font-size: 12pt;
  font-weight: 400;
  line-height: 1.75 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #f4f4f4 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3c3b3b !important;
  font-family: "Raleway", Helvetica, sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: 0.25em;
  line-height: 1.65;
  margin: 0 0 1em;
  text-transform: uppercase !important;
}

h6 {
  font-size: 8pt;
  letter-spacing: 0.2em;
}
.header--bold {
  font-weight: 900 !important;
}

.label {
  text-transform: capitalize !important;
}

article.ui.header:is(.publication) {
  margin-bottom: 1rem;
}

.dropdown__item {
  display: "grid";
  padding: "0";
}

.ui.floating.dropdown > .menu.menu {
  margin-top: 0 !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
  border-top: none;
  width: inherit;
}

.project--bold.project--bold {
  font-size: 16px !important;
  line-height: 1;
}

p {
  color: #3c3b3b;
  font-weight: 400;
}

.item.item.item:not(.menu__item--sidenav) {
  font-family: "Raleway", Helvetica, sans-serif !important;
  font-weight: 500;
  letter-spacing: 0.25em;
  line-height: 1.65;
  font-size: 0.8rem;
  text-transform: uppercase !important;
}

.menu__item--sidenav {
  font-weight: 100;
  margin-left: auto;
  display: none !important;
  cursor: pointer;
  text-transform: uppercase !important;
}

.sidenav__container {
  top: 48.5px !important;
  box-shadow: none !important;
  display: none !important;
}

.item:first-child {
  font-family: "Raleway", Helvetica, sans-serif !important;
  font-weight: 900 !important;
  font-size: 1rem;
  text-transform: uppercase !important;
  border-right: solid 0.5px rgba(34, 36, 38, 0.15);
}

.item:not(:first-child):not(:nth-last-child(2)):not(:last-child):not(.menu__item--sidenav):after {
  content: "";
  height: 40%;
  width: 0.25px;
  position: absolute;
  right: 0;
  top: 30%;

  background-color: rgba(34, 36, 38, 0.35);
}

.button--text.button--text.button--text {
  font-family: "Raleway", Helvetica, sans-serif !important;
  font-weight: 500;
  letter-spacing: 0.2em !important;
  line-height: 1.65;
  font-size: 0.7rem;
  text-transform: uppercase !important;
}

a.ui.ui:not(.disabled__link) {
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  border-bottom: 1px dotted hsla(0, 0%, 62.7%, 0.65);
  color: inherit;
  text-decoration: none;
}

a.ui.ui:hover:not(.disabled__link) {
  color: #2e59ba;
}

.disabled__link {
  cursor: unset !important;
}

.menu__container--background {
  background-color: white !important;
}
#root {
  background: #f4f4f4;
  height: 100%;
}

.main {
  background: #f4f4f4;
  height: 100%;
  padding: 0 0 0 4.5rem !important;
  padding-top: calc(4rem + 46px) !important;
  margin-bottom: 0 !important;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
}
.main__left,
.main__right {
  padding-top: 0 !important;
}

.main__right--background {
  height: fit-content;
  background-color: white;
  overflow-y: auto;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;

  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.main__right__text {
  padding: 3rem;
}

.social__link {
  color: inherit;
}

.sidebar__info {
  margin-top: 0 !important;
}

.sidebar__container {
  display: flex;
  height: 100%;
  flex-direction: column !important;
  align-content: flex-start;
  margin: 0 !important;
  padding-top: 0 !important;
  gap: 1rem;
  margin-bottom: -2rem;
}

.sidebar__hr {
  width: 75%;
  margin: 1.5rem 0;
}

.sidebar__profile {
  height: fit-content;
  padding: 0 !important;
  gap: 1.5rem;
}

.sidebar__profile > img {
  border-radius: 3%;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.sidebar__about {
  gap: 1em;
  font-weight: 900 !important;
}

.sidebar__image {
  width: min(200px, 100%) !important;
  border-radius: 1%;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border: 0.5px solid rgba(34, 36, 38, 0.15) !important;
}

.sub.sub.header:not(.projects__text) {
  letter-spacing: 0.125em;
  font-size: 0.9rem !important;
}

.sidebar__about > .sub.header {
  margin-top: 1rem !important;
}

.projects__card {
  flex: 1 1 100%;
}
.card--row-reversed,
.card--row {
  padding: 0 !important;
}
.card--row-reversed {
  flex-direction: row-reverse !important;
}
.projects__card__container {
  margin: 0 !important;
  padding: 0.5em 0 1.5em 0 !important;
}

.accordian__overflow > .sub.header.title {
  padding: 0;
  width: fit-content;
}

.accordian__overflow > .sub.header.title > span:before {
  content: "Show More ";
}

.accordian__overflow > .sub.header.title.active > span:before {
  content: "Hide ";
}

.accordian__overflow > .sub.header.title.active > i:before {
  content: "\f077";
}

@media (max-width: 588px) {
  .sidebar__column.sidebar__column.sidebar__column.sidebar__column.sidebar__column {
    width: 100% !important;
  }
  .mobile-centered {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
  }
}

@media (max-width: 1160px) {
  .menu__item--sidenav {
    display: block !important;
  }

  .sidenav__container {
    display: unset !important;
  }
  #menu__container > .item:not(:first-child):not(.menu__item--sidenav) {
    display: none !important;
    position: relative;
  }
}

@media (max-width: 767px) {
  .main.main {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  h4.sidebar__about {
    text-align: center;
  }

  .pusher
    > div.main
    > div.main__right--background.main__right--background.main__right--background {
    padding: 2rem 2rem !important;
  }

  .pushable {
    height: 100vh;
  }

  .main__right__text.main__right__text,
  .main__right__text.main__right__text > div {
    padding: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .sidebar__info.sidebar__info {
    margin-top: 0;

    width: fit-content;
    /* white-space: break-spaces; */
    flex: 0.5 1.5 50%;
  }
}

@media (min-device-width: 768px) and (max-width: 991px) {
  .main {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .main__left.main__left {
    padding: 0 !important;
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .main__right--background {
    max-height: calc(100vh - calc(4rem + 46px)) !important;
    margin-right: 0.5em;
  }
}

@media (max-width: 400px) {
  .ui.header {
    white-space: normal !important;
  }
}

@media (prefers-reduced-motion) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: unset !important;
  }
}
